<template>
  <div>
    <!-- <v-row>
      <v-col>
        <v-text-field outlined v-model="search_data.name" label="搜尋帳戶名稱" hide-details="auto"></v-text-field>
      </v-col>
      <v-col>
        <div class="d-flex align-center justify-space-between">
          <v-select
            label="帳戶類別"
            :items="search_order_type"
            item-text="item-text"
            item-value="item-value"
            hide-details
            outlined
            v-model="search_data.order_type"
          ></v-select>
          <v-btn color="primary" class="ml-2" @click="searchOrder">搜尋</v-btn>
        </div>
      </v-col>
    </v-row> -->
    <!-- <v-row>
      <v-col>
        <router-link :to="{ name: 'add-order' }">
          <v-btn color="primary">Add Order</v-btn>
        </router-link>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col cols="12">
        <data-table-with-pagination
          :headers="order_header"
          :dataList="order_list"
          :loading="load_table"
          :options="options"
          :itemLength="total_items"
        ></data-table-with-pagination>
      </v-col>
    </v-row>
    <AlertBox :type="alertBox.type" :text="alertBox.text" :show="alertBox.show"></AlertBox>
  </div>
</template>

<script>
import DataTableWithPagination from '@/components/DataTableWithPagination.vue'
import AlertBox from '@/components/AlertBox'
import { globalFunc } from '@/global'
import { mapActions } from 'vuex'
import { getData, postData } from '@/api'

export default {
  name: 'order-management',
  components: {
    DataTableWithPagination,
    AlertBox,
  },
  data: () => ({
    isSuperAdmin: false,
    user_center_id: -1,
    verifiedError: false,
    
    order_header: [
      {
        text: 'Order ID',
        value: 'id',
      },
      {
        text: 'User ID',
        value: 'user',
      },
        {
        text: 'Create Date',
        value: 'create_date',
      },
      {
        text: '',
        value: 'view_button',
      },
    ],
    order_list: [{id:1,user:"Jimmy",create_date:"2022-02-06 15:30",route_link: 'order-management-detail'},{id:2,user:"Joan",create_date:"2022-02-06 15:30",route_link: 'order-management-detail'}],
    load_table: true,
    search_data: {
      name: '',
      order_type: '',
    },
    search_order_type: [
      {
        'item-text': '所有類別',
        'item-value': '',
      },
      {
        'item-text': '用戶',
        'item-value': 'name',
      },
    ],
    alertBox: {
      show: false,
      text: '',
      type: '',
    },
    total_items: 0,
    current_page: 0,
    current_limit: 10,
    options: {},
  }),
  methods: {
    ...mapActions(['reset']),
    /**
   * Initialize and read order data
   * @param {string} search_name - The search name that the input search_data.name insert.
   * @param {string} search_type - The search type that the input search_data.order_type insert.
   */
    async initOrderData(search_name = '', search_type = '') {
      try {
        if (this.verifiedError) {
          return
        }

        this.load_table = true

        let send_data = {
          page: this.current_page,
          limit: this.current_limit,
        }

        if (!this.isSuperAdmin) {
          send_data.center_id = this.user_center_id
        }

        if (globalFunc.DataValid(search_name)) {
          send_data.search = search_name
        }

        if (globalFunc.DataValid(search_type)) {
          send_data.user_type = search_type
        }

        let params = new URLSearchParams()
        params.set('get_all_order', JSON.stringify(send_data))

        let result = await getData(params)
        console.log('--- Get order data ---')
        console.log(result[0])
        let temp = []
        for (let i = 0; i < result.length; i++) {
          temp.push({
            id: result[i].id,
            name: result[i].name,
            icon: result[i].icon,
            route_link: 'order-management-detail',
          })
        }
        // this.order_list = temp
        
        this.load_table = false
      } catch (error) {
        console.log('--- Get order data fail ---')
        this.load_table = false
        this.order_list = []
        console.log(error)
      }
    },
    /**
    * Search Order function
    */
    searchOrder() {
      this.initOrderData(this.search_data.name, this.search_data.order_type)
    },
    /**
     * Kick out user
    */
    kickout() {
      console.log('--- Validation Fail ---')
      this.alertBox.text = '登入憑證過期，3秒後重新導向'
      this.alertBox.type = 'error'
      this.alertBox.show = true

      setTimeout(() => {
        this.alertBox.show = false
        let send_data = []
        send_data['method'] = 'resetData'
        this.reset(send_data)
        this.$router.push({ name: 'Login' })
      }, 3000)
    },
  },

  /**
   * Initialize everything at beginning
   * @constructor
   */
  created() {
    setTimeout(async () => {

      console.log('--- Validation ---')
      if (globalFunc.DataValid(this.$store.state.api.login_data)) {
        try {
          this.initOrderData()
        } catch (error) {
          console.log('--- Check User Permission Fail ---')
          console.log(error)
          this.load_table = false
          this.verifiedError = true

          this.alertBox.text = '資料取得失敗'
          this.alertBox.type = 'error'
          this.alertBox.show = true

          setTimeout(() => {
            this.alertBox.show = false
          }, 3000)
        }
      } else {
        this.kickout()
      }
    }, 200)
  },
}
</script>
